@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchLink {
  margin-top: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--colorWhite);
  padding: 8px 8px;
  border-radius: 100px;
  z-index: 2;
  @media (--viewportMedium) {
    padding: 16px 16px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }

  & input {
    font-size: 16px;
    border-radius: 100px;
    &::placeholder {
      font-size: 20px;
      font-weight: bold;
    }
  }
  & ul {
    width: 80%;
  }
}

.searchInputRoot {
  flex-grow: 1;
}

.title {
  color: var(--colorGrey);
  font-size: 52px;
  font-weight: bold;
  text-align: center !important;
  justify-self: center !important;
  z-index: 1;
  @media (--viewportMedium) {
    font-size: 70px;
  }
  @media (--viewportLarge) {
    font-size: 70px;
  }
}

.description {
  color: var(--colorGrey);
  font-size: 18px;
  font-weight: bold;
  text-align: center !important;
  justify-self: center !important;
  z-index: 1;
  @media (--viewportMedium) {
    font-size: 25px;
  }
  @media (--viewportLarge) {
    font-size: 25px;
  }
}

.mainContainer {
  position: relative;
}

.illustrations {
}

.oval {
  position: absolute;
  height: 120px;
  width: auto;
  right: 0;
  top: 0;
  z-index: 1;
  @media (--viewportMedium) {
    height: 180px;
  }
}

.leaf {
  position: absolute;
  height: 72px;
  width: auto;
  left: 0;
  top: 200px;
  z-index: 1;
  @media (--viewportMedium) {
    height: 110px;
  }
}

.ovalHalf {
  position: absolute;
  height: 160px;
  width: auto;
  left: 20%;
  bottom: -80px;
  z-index: 1;
  @media (--viewportMedium) {
    height: 260px;
    left: 315px;
    bottom: -110px;
  }
}

.spark {
  position: absolute;
  height: 120px;
  width: auto;
  right: 0;
  top: 53%;
  z-index: 1;
  @media (--viewportMedium) {
    height: 165px;
    right: 346px;
    top: 60%;
  }
}

.star1 {
  position: absolute;
  height: 40px;
  width: auto;
  right: 20%;
  top: 46px;
  z-index: 1;
  @media (--viewportMedium) {
    right: 480px;
    top: 46px;
  }
}

.star2 {
  position: absolute;
  height: 30px;
  width: auto;
  left: 100px;
  top: 50px;
  z-index: 1;
  @media (--viewportMedium) {
  }
}

.star3 {
  position: absolute;
  height: 30px;
  width: auto;
  right: 10%;
  bottom: -15px;
  z-index: 1;
  @media (--viewportMedium) {
    right: 363px;
    bottom: -15px;
  }
}
