@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
  z-index: 1;
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.whyUs {
  & > div {
    & > div {
      align-items: center !important;
    }
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--colorGrey) !important;
    }
    & ul {
      list-style: none;
      & li {
        position: relative;
      }
      & li::before {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        left: -36px;
        background-image: url('../../../../assets/images/icons/iconCheck.svg');
        background-size: 24px 24px;
        width: 24px;
        height: 24px;
      }
       {
      }
    }
    & a {
      /* button defaults */
      display: block;
      width: 100%;
      min-height: 56px;
      margin: 24px 0;

      /* Padding is only for <a> elements where button styles are applied,
      buttons elements should have zero padding */
      padding: 16px 0 16px 0;

      transition: all var(--transitionStyleButton);
      cursor: pointer;

      /*
      * Font: most font styles are defined earlier in this file
      */
      font-weight: var(--fontWeightSemiBold);

      /* Button text styles */
      /* Secondary button text is centered without decorations */
      text-align: center;
      text-decoration: none;

      /* Secondary button has clear borders */
      border-width: 1px;
      border-style: solid;
      border-color: var(--marketplaceColor);
      border-radius: var(--borderRadiusMedium);

      /* Button colors: secondary */
      background-color: var(--colorSecondaryButton);
      color: var(--colorGrey700);
      border-radius: 100px;
      color: var(--marketplaceColor);
      max-width: 265px;
      box-shadow: none;

      @media (--viewportMedium) {
        padding: 20px 0 20px 0;
      }

      &:focus,
      &:hover {
        outline: none;
        box-shadow: var(--boxShadowButton);
        border-color: var(--marketplaceColorDark);
        color: var(--marketplaceColorDark);
        background-color: var(--colorSecondaryButton);
      }

      &:hover {
        text-decoration: none;
      }
      &:disabled {
        box-shadow: none;
        cursor: not-allowed;
        border-color: var(--colorGrey100);
        background-color: var(--colorGrey100);
        color: var(--colorWhite);
      }
    }
  }
}

.mainContainer {
  position: relative;
}

.star5 {
  position: absolute;
  height: 30px;
  width: auto;
  right: 24px;
  top: 372px;
  z-index: 1;
  @media (--viewportMedium) {
    right: 480px;
    top: 46px;
  }
}

.star2 {
  position: absolute;
  height: 40px;
  width: auto;
  left: 24px;
  top: 342px;
  z-index: 1;
  @media (--viewportMedium) {
    left: 173px;
    bottom: 46px;
  }
}
