@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  max-width: 100% !important;
  color: var(--colorGrey) !important;
  text-align: center !important;
  font-size: 48px;
  z-index: 2;
  padding-top: 64px;
  @media (--viewportMedium) {
    font-size: 70px;
    padding-top: 0;
    max-width: 75% !important;
  }
}

.description {
  color: var(--colorGrey) !important;
  text-align: center !important;
  justify-self: center !important;
  z-index: 2;
}

.ctaButton {
  border-radius: 100px !important;
  padding: 36px 0 !important;
  font-size: 18px !important;
  font-weight: bold !important;
  margin: 24px 0 !important;
  width: 100% !important;
  text-align: center !important;
  z-index: 2;
  @media (--viewportMedium) {
    width: 307px !important;
  }
}

.mainContainer {
  position: relative;
}

.illustrations {
}

.oval {
  position: absolute;
  height: 100px;
  width: auto;
  right: 0;
  bottom: -36px;
  z-index: 1;
  @media (--viewportMedium) {
    right: 0;
    bottom: -36px;
  }
}

.sun {
  position: absolute;
  height: 150px;
  width: auto;
  right: 0px;
  top: -56px;
  z-index: 1;
  @media (--viewportMedium) {
    left: 36px;
    top: 200px;
  }
}

.flower {
  position: absolute;
  height: 150px;
  width: auto;
  left: 36px;
  bottom: -75px;
  z-index: 1;
  @media (--viewportMedium) {
    left: 315px;
    bottom: -75px;
  }
}

.star1 {
  position: absolute;
  height: 40px;
  width: auto;
  right: 48px;
  bottom: 46px;
  z-index: 1;
  @media (--viewportMedium) {
    right: 480px;
    top: 46px;
  }
}

.star2 {
  position: absolute;
  height: 30px;
  width: auto;
  left: 100px;
  top: 50px;
  z-index: 1;
  @media (--viewportMedium) {
    left: 100px;
    top: 50px;
  }
}
