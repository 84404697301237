@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.setup {
  & > div {
    padding-top: 96px;
    @media (--viewportMedium) {
      padding-top: 64px;
    }
    & > div {
      align-items: center !important;
      & > div {
        & > div {
        }
      }
    }
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--colorGrey) !important;
      text-align: center !important;
    }
  }

  & .media {
    width: auto;
    height: 234px;
    & img {
      object-fit: contain !important;
    }
  }

  & .text {
    max-width: 234px;
    margin-left: auto;
    margin-right: auto;
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 16px;
      text-align: center;
    }
  }
}

.mainContainer {
  position: relative;
}

.rainbow {
  position: absolute;
  height: 160px;
  width: auto;
  left: 0;
  top: -45px;
  z-index: 1;
  @media (--viewportMedium) {
    left: 0;
    top: -45px;
  }
}

.spark {
  position: absolute;
  height: 100px;
  width: auto;
  right: 50px;
  top: -52px;
  z-index: 1;
  @media (--viewportMedium) {
    right: 346px;
    top: -52px;
  }
}

.star {
  position: absolute;
  height: 40px;
  width: auto;
  left: 100px;
  bottom: -18px;
  z-index: 1;
  @media (--viewportMedium) {
    left: 100px;
    bottom: -18px;
  }
}
