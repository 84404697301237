@import '../../../../styles/customMediaQueries.css';

.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.sectionAd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.adWrapper {
  width: 90vw !important;
  margin: 24px auto !important;
  @media (--viewportMedium) {
    width: 100% !important;
  }
}

.googleAd {
  max-width: 100% !important;
  overflow: hidden !important;
  margin: 0 auto !important;
}

.disclaimer {
  font-size: 10px;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey);
  padding: 12px 0;
  text-align: center;
  line-height: 13px;
}
