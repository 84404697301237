@import '../../../../styles/customMediaQueries.css';

.howItWorks {
  background: var(--colorWhite);
  & > div {
    display: flex;
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto 0 auto;
    padding: 160px 0;
    flex-direction: column;
    gap: 48px;
    @media (--viewportMedium) {
      flex-direction: row;
      gap: 0px;
      padding: 120px 0;
    }
  }
}

.blocksContainer {
  padding-top: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  padding: 0px 64px 0px 64px !important;
  @media (--viewportMedium) {
    padding: 0px 32px 0 32px !important;
  }
  & > div {
    display: flex;
    gap: 72px;
    justify-content: center;
    align-items: center;
    & > div:first-child {
      width: 105px;
    }
    & img {
      width: 105px;
      height: auto;
    }
  }
}

.header {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  padding-top: 32px !important;
  max-width: 350px !important;
  @media (--viewportMedium) {
    justify-content: flex-start !important;
  }
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center !important;
    @media (--viewportMedium) {
      text-align: left !important;
    }
  }
  & p {
    text-align: center !important;
    @media (--viewportMedium) {
      text-align: left !important;
    }
  }
}

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
  @media (--viewportMedium) {
    max-width: 50%;
  }
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.mainContainer {
  position: relative;
}

.abstractLines {
  position: absolute;
  height: 100px;
  width: auto;
  right: 0;
  top: 32px;
  z-index: 1;
  @media (--viewportMedium) {
    height: 120px;
    right: 0;
    top: 60px;
  }
}

.star {
  position: absolute;
  height: 40px;
  width: auto;
  left: 10%;
  bottom: 30px;
  z-index: 1;
  @media (--viewportMedium) {
    height: 40px;
    left: 200px;
    bottom: 120px;
  }
}
